

import packageJson from '../package.json';

import 'hammerjs';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { initializeApp } from 'firebase/app';
import { commonEnvironment } from '@libs/shared/util-environments';
import { Canal } from '@libs/entity-lib';

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);


document
.querySelector('html')
?.setAttribute('social-sport-version', packageJson.version);


initializeApp(commonEnvironment.apps[Canal.SPORT_CENTER].firebaseConfig);